<template lang="">
    <div class="row">
         <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="col-12">
            <card :title="table.title">
                <div class="row align-center">
                  <div class="col-md-4 text-center" style="position: relative;">
                    <!-- <base-input label="วันที่/เวลา เริ่มต้น" v-model="forms.start" type="date"  placeholder="Enter text"></base-input> -->
                    <datetime type="date" class="form-control input-datetime" format="yyyy-MM-dd" v-model="forms.start"> </datetime>
                  </div>
                  <div class="col-md-4  text-center" style="position: relative;">
                    <!-- <base-input label="วันที่/เวลา สิ้นสุด" v-model="forms.end" type="date"  placeholder="Enter text"></base-input> -->
                    <datetime type="date" class="form-control input-datetime" format="yyyy-MM-dd" v-model="forms.end"></datetime>
                  </div>
                  <div class="col-md-1  text-center" style="position: relative;">
                    <base-button type="primary"  @click="findReport()">
                        ค้นหา
                    </base-button>
                  </div>
                  <div class="col-md-3  text-center" style="position: relative;">
                    <base-button style="margin-right: 5px;" round icon type="primary"  @click="LoadData()">
                        <i class="tim-icons icon-refresh-02"></i>
                    </base-button>
                  </div> 
                </div>
                <div class="row">
                    <div class="col-12">
                        <br />
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center ">
                        <div class="icon-container">
                            <b-icon icon="cash-stack" variant="success" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">รวมยอดฝาก</p>
                            <h4 class="mb-0 font-weight-bold">{{ finance.totalDeposit ? formatCurrency(finance.totalDeposit) : '0.00' }}</h4>
                        </div>
                    </b-card>
                  </div>
                </div>
                <div class="row">
                    <div class="col-12">
                    <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากชื่อ ... <Enter>" v-on:keyup.enter="searchWord()">
                    </base-input>
                    </div>
                  <div class="col-12">
                    <card class="shadow-sm">
                        <base-table-finance :data="table.data" :columns="table.columns" :columnth="table.columnsTH" :sort="table.sort" @SortTable="SortTable" :title="table.title" />
                    </card>
                    </div>
                </div>
            </card>
        </div>  
    </div>
</template>
<script>
import { BaseTableFinance } from '@/components';
import Loading from 'vue-loading-overlay';
import utils from '@/utils';
import { Datetime } from 'vue-datetime';
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
import axios from 'axios';
import config from '@/config.js';
const tableColumns = [ "gameId", "name", "event", "amount", "result",  "created_at" , "refer"];
const tableColumnsTH = ["เกมไอดี", "ชื่อผู้ใช้", "กระทำ", "จำนวน", "โอโต้",  "วันที่" , "อ้างอิง"];
const tableData = []; 
export default {
    components: {
      Datetime,
      Loading,
      BaseTableFinance
    },
    data() {
        return {
            fullPage: false,
            isLoading: true,
            table: {
                sort:{
                    even:'desc',
                    col:'created_at'
                },
                title: "รายการ ฝากเครดิต",
                columns: [...tableColumns],
                columnsTH: [...tableColumnsTH],
                data: [...tableData]
            },
            forms:{},
            finance:[],
            searchName: '',
            allResult:[],
            

        }
    },
    methods: {
        LoadData() {
            if (utils.permission('FINANCE_DEPOSIT_CREDIT_VIEW')) {
                const currentDate = new Date().toISOString().split('T')[0];
                axios(config.api('post', '/finance/depositCredit', { params: { startDate: currentDate, endDate: currentDate } }))
                    .then((response) => {
                        console.log(response.data.ResultData);
                        this.table.data = response.data.ResultData.allResult;
                        this.allResult = response.data.ResultData.allResult;
                        this.finance = response.data.ResultData.typeOfFinance;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        const errorMessage = error.response?.data?.Message || 'เกิดข้อผิดพลาดในการโหลดข้อมูล ติดต่อผู้ดูแลระบบด่วนจ้า';
                        this.$alert(errorMessage, 'Error', 'error');
                    });                
            } else {
                this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน', 'Permission', 'warning');
                this.isLoading = false;
            }
        },
        findReport() {
            this.isLoading = true;
            axios(config.api('post', '/finance/depositCredit', { params: { startDate: this.forms.start, endDate: this.forms.end } }))
                    .then((response) => {
                        console.log(response.data.ResultData);
                        this.table.data = response.data.ResultData.allResult;
                        this.allResult = response.data.ResultData.allResult;
                        this.finance = response.data.ResultData.typeOfFinance;
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        const errorMessage = error.response?.data?.Message || 'เกิดข้อผิดพลาดในการโหลดข้อมูล ติดต่อผู้ดูแลระบบด่วนจ้า';
                        this.$alert(errorMessage, 'Error', 'error');
                    });
        },
        searchWord() {
            if (this.searchName.length <= 0) {
                this.table.data = this.allResult;
            }
            else{
                this.table.data = this.filteredData();
            }
        },
        SortTable(column){ 
            // console.log('column',column);
            
            let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
            let ObjK = {};
            ObjK[column] = this.table.data;
            this.table.sort.col = column;
            this.table.sort.even = sortType; 
            this.table.data = utils.Sort[sortType](ObjK) 

        },
        formatCurrency(value) {
            return new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
            }).format(parseFloat(value));
        },
        filteredData() {
            const searchTerm = this.searchName.toLowerCase();
            const filtered = this.table.data.filter((item) => {
                return (
                (item.gameId && item.gameId.toLowerCase().includes(searchTerm)) ||
                (item.name && item.name.toLowerCase().includes(searchTerm))
                );
            });
            return filtered.length > 0 ? filtered : [];
        }
    },
    mounted() {
        let startDate =  utils.forMatDate(Math.floor(Date.now()));
        // let endDate =  utils.forMatDate((Math.floor(Date.now() / 1000)+((60*60)*24))*1000);
        let endDate =  utils.forMatDate(Math.floor(Date.now()));
        this.forms.start = `${startDate}`;
        this.forms.end = `${endDate}`;
        this.LoadData();        
    },
    computed: {
  },
}
</script>
<style scoped>
.row.align-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>