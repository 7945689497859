<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3"> 
        <card>
          <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากชื่อ/เบอร์/เลขบัญชี ... <Enter>" v-on:keyup.enter="searchWord()">
          </base-input>
        </card>
      </div>
      <div class="table-responsive">
        <base-alert v-if="isAlert.open" :type="isAlert.type" v-on:click="isAlert.open == false">
          <strong> {{isAlert.tip}} </strong> {{isAlert.msg}}
          <button type="button" aria-label="Close" class="close" @click="isAlert.open = false"><span aria-hidden="true"><i class="tim-icons icon-simple-remove"></i></span></button>
        </base-alert>
        <base-table-costomer :data="table.data" :columns="table.columns" @user-modals="findUserEvet" thead-classes="text-primary"> </base-table-costomer>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>
  <b-modal v-model="proDialog" title="Hello From My Modal!">
      test
      <template #modal-footer>
        <b-button variant="secondary" @click="proDialog = false">Cancel</b-button>
        <b-button variant="primary">OK</b-button>
      </template>
    </b-modal>
  <modal :show.sync="modals.open"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
          <template>
            <strong>MEMBER :</strong> {{ modals.title }}
          </template>

          <template v-if="modals.eve == 'vocher'">
            <form role="form">
                <label for="">สามารถ Clipboard จาก ปุ่มได้เลย</label>
                <base-input alternative type="text"   v-model="genVocher" ref="genvoucher"> </base-input>
            </form>
          </template>
          <template v-if="modals.eve == 'block'">
            <div class="text-center text-muted mb-4">
              <strong>ต้องการแบนสมาชิก</strong>
            </div>
            <form role="form">
                <label for="">แจ้งหมายเหตุว่า</label>
                <base-input alternative
                            type="text"
                            placeholder="แจ้งหมายเหตุว่า"
                            v-model="modals.commit" >
                </base-input>
            </form>
          </template>
          <template v-if="modals.eve == 'info'">
            <div class="text-center text-muted mb-4">
              <strong>แก้ไขข้อมูลสมาชิก</strong>
            </div>
            <form role="form" class="row">
              <div class="col-6">
                <base-input label="ชื่อจริง" type="text" v-model="modals.data.firstName" placeholder="Enter text"></base-input>
              </div>
              <div class="col-6">
                <base-input label="นามสกุล" type="text" v-model="modals.data.lastName" placeholder="Enter text"></base-input>
              </div>
              <div class="col-6"> 
                <base-input label="Account" disabled type="text" v-model="modals.data.account" placeholder="Enter text"></base-input>
              </div>
              <div class="col-6">
                <base-input label="เบอร์โทร" type="text" v-model="modals.data.tel" placeholder="Enter text"></base-input>
              </div>
              <div class="col-6">
                <base-input label="รหัสผ่าน" type="text" v-model="modals.data.passwod" placeholder="Enter text"></base-input>
              </div>
              <div class="col-6">
                <base-input label="ธนาคาร">
                  <select class="form-control" id="typeBank" v-model="modals.data.typeBank">
                    <option v-for="(item, index) in selectBank" :value="item.id">{{item.name}}</option>
                  </select>
                </base-input>
              </div>
              <div class="col-6">
                <base-input label="เลขบัญชี" type="text" v-model="modals.data.numberBank" placeholder="Enter email"></base-input>
              </div>
              <div class="col-6">
                <base-input label="กระเป๋าสำรอง" type="text" v-model="modals.data.balance" placeholder="กระเป๋าสำรอง"></base-input>
              </div>
              <div class="col-6">
                <base-input label="เครดิต" type="text"  v-model="modals.data.credit" placeholder="เครดิต"></base-input>
              </div>
              <div class="col-6">
                <base-input label="ฟรีสปิ้น" type="text" v-model="modals.data.freespin" placeholder="ฟรีสปิ้น"></base-input>
              </div>
            </form>
          </template>
          <template v-if="modals.eve == 'changepro'">
            <div>
              <div class="text-center text-muted mb-4">
              <strong>เปลี่ยนโปรโมชั่น</strong>
            </div>
            <div class="col-12">
              <base-input label="โปรโมชั่น">
              <select
                class="form-control"
                id="promotion"
                v-model="modals.data.promotion"
              >
                <option
                  v-for="item in selectPromotion"
                  :key="item._id"
                  :value="item._id"
                >
                  {{ item.title }}
                </option>
              </select>
              </base-input>
            </div>
          </div>
          </template>
          <template slot="footer">
            <base-button type="secondary" @click="modals.open = false">Close</base-button>
            <base-button v-if="modals.eve == 'block'" v-show="modals.commit" type="primary" style="width: 82%;" @click="saveChanges()">Save changes</base-button>
            <base-button v-if="modals.eve == 'info'" type="primary" style="width: 82%;" @click="saveChanges()">Submit</base-button>
            <base-button v-if="modals.eve == 'changepro'" type="primary" style="width: 82%;" @click="savePromotion()">Save changes</base-button>
            <base-button v-show="genVocher"
              v-clipboard:copy="genVocher"
              v-clipboard:success="ClipboardVouche"
              type="primary" style="width: 82%;" v-if="modals.eve == 'vocher'">Clipboard</base-button>
          </template>
      </card>
  </modal>
</div>
</template>
<script>
import {
  BaseTableCostomer,
  BaseAlert,
  Modal
} from "@/components";
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
import axios from 'axios';
import config from '@/config.js';
import utils from '@/utils';
import { log } from "console";
const tableColumns = [ "name", "account", "balance", "typeBank", "numberBank", "deposit" ,  "withdraw" ];
const tableData = [];

export default {
  components: {
    BaseTableCostomer,
    SlidingPagination,
    Modal,
    BaseAlert,
    Loading
  },
  data() {
    return {
      table: {
        title: "ข้อมูลสมาชิก",
        columns: [...tableColumns],
        data: [...tableData]
      },
      modals: {
        open: false,
        title: '',
        eve: '',
        data :{},
        commit: '',
        vocher: ''
      },
      friends:{},
      isAlert: {open:false,tip:'',msg:'',type:'danger'},
      selectBank: config.selectBank,
      selectPromotion: [{value:0,key:0,title:'ไม่มีโปรโมชั่น'}],
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: '',
      genVocher: '',
      proDialog: false
    };
  },
  methods: {
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      axios(config.api('get', '/costomer/'+selectedPage)).then((tableReq)=>{
        this.table.data = tableReq.data.ResultData.skipdata;
        this.currentPage = selectedPage;
        this.isLoading = false;
      },(error)=>{
        this.$alert(error.response.data.Message,'Error','error');
      });
    },
    savePromotion(){
      axios(config.api('post', '/freecredit',{params: this.modals.data})).then((tableReq)=>{
          this.$alert('เปลี่ยนโปรโมชั่นสําเร็จ','สําเร็จ','success');
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      this.modals.open = false;
    },
    findFriends(){
      this.searchName = this.friends.tel;
      this.modals.open = false;
      this.searchWord();
    },
    findFriendsKey(item){
      if(item){
        axios(config.api('post', '/costomer/friendsKey',{keyid:item})).then((tableReq)=>{
           this.friends =  tableReq.data.ResultData;
        },(error)=>{
          this.$alert(error.response.data.Message,'Error','error');
        });
      }else{
        this.friends = '';
      }
    },
    findUserEvet(item){
      if(utils.permission('COSTOMER_VIEW')){
        if(item.eve == 'pointFriends'){
          // console.log('item>',item.item.name);
          
          window.location.href = `/?#/friendspoint?id=${item.item.name}`
        }else if(item.eve == 'ceditAgent'){
          ///this.modals.open = true;
          // this.$alert('ยังไม่เปิดใช้งาน','Warning','warning');

          // this.modals.title = item.item.name;
          // this.modals.eve = item.eve;
          // this.modals.data = item.item;
          // this.modals.open = true;

        }else if(item.eve == 'info'){ 
          //console.log('MEMBERINFO');
          this.isLoading = true; 
           axios(config.api('get', `/costomer/info/${item.item._id}`)).then((response)=>{
            item.item.credit = response.data.ResultData.credit
            this.modals.title = item.item.name;
            this.modals.eve = item.eve;
            this.modals.data = item.item;
            this.modals.commit = '';
            utils.localDB.set('memberinfo',this.modals.data);
            this.findFriendsKey(item.item.friendsKey);
            this.modals.open = true;
            this.isLoading = false;
          },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
          }); 
        }else if(item.eve == 'vocher'){
          if(item.item.freeCode == 0){
            (async () => {
              try {
                let tableReq = await axios(config.api('post', '/costomer/genvocher',{customer:item.item._id}));
                this.modals.open = true;
                this.modals.eve = item.eve;
                this.modals.title = item.item.name;
                this.genVocher = tableReq.data.ResultData;
              } catch (error) {
                this.modals.open = true;
                this.genVocher = 'Gen ใหม่อีกครั้ง';
              }
            })();
          }
        }else if(item.eve == 'memberlog'){
          this.$router.push(`/costomer/${item.item._id}`); 
        }else if(item.eve == 'tranferbalance'){
          this.$confirm(`ยืนยันการโยกเงินจำนวน ${item.item.balance} บาท หรือไม่`, 'แน่ใจว่าตรวจสอบที่มาของเงินแล้ว?', 'warning').then(() => {
            axios(config.api('post', '/transferCredit',{user: item.item})).then((tableReq)=>{
              this.$alert('โยกเงินสําเร็จ','สําเร็จ','success');
            },(error)=>{
              this.$alert(error.response.data.Message,'Error','error');
            });
          }).catch(() => {
          });
        }else{
          this.modals.open = true;
          this.modals.title = item.item.name;
          this.modals.eve = item.eve;
          this.modals.data = item.item;
          this.modals.commit = '';
        }
      }
    },
    saveChanges(){
      if(this.modals.eve == 'block'){
        /////
        if(utils.permission('COSTOMER_BLOCK')){
          if(this.modals.commit && this.modals.eve == 'block'){
            axios(config.api('post', '/costomer/block',{
              id: this.modals.data._id,
              status: (this.modals.data.status == 1)?'Block':'Active',
              commit: this.modals.commit
            })).then((response)=>{
              this.isLoading = false;
              this.modals.open = false;
              this.modals.data.status = (this.modals.data.status == 0)?1:0;
            },(error)=>{
              this.modals.open = false;
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            });
          }
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
          this.isLoading = false;
          this.modals.open = false;
        }
      }else if(this.modals.eve == 'changepro'){
        // console.log(this.modals.commit);
      }else{
        /////
        if(utils.permission('COSTOMER_EDIT')){
          ///// Save Send edit
          let memberInfo = utils.localDB.get('memberinfo');
          if(memberInfo.firstName != this.modals.data.firstName ||
             memberInfo.lastName != this.modals.data.lastName || 
             memberInfo.tel != this.modals.data.tel ||
             memberInfo.passwod != this.modals.data.passwod ||
             memberInfo.typeBank != this.modals.data.typeBank ||
             memberInfo.balance != this.modals.data.balance ||
             memberInfo.credit != this.modals.data.credit ||
             memberInfo.freespin != this.modals.data.freespin ||
             memberInfo.numberBank != this.modals.data.numberBank){
            let data = {
              _id: this.modals.data._id,
              data: {
                firstName: this.modals.data.firstName,
                lastName: this.modals.data.lastName,
                tel: this.modals.data.tel,
                account: this.modals.data.account,
                balance: parseInt(this.modals.data.balance),
                credit: parseInt(this.modals.data.credit),
                freespin: parseInt(this.modals.data.freespin),
                passwod: this.modals.data.passwod,
                typeBank: this.modals.data.typeBank,
                numberBank: this.modals.data.numberBank
              }
            }
            this.$prompt("เหตุผลการกระทำ").then((commit) => {
              this.isLoading = true;
              this.modals.open = false;
              //// Submit send data
               data.commit = commit;
               axios(config.api('post', '/costomer/modify',data)).then((response)=>{
                 this.isLoading = false;
                 this.$alert(response.data.Message,'Success','success');
                 //console.log('response',response.data);
               },(error)=>{
                 this.isLoading = false;
                 this.$alert(error.response.data.Message,'Error','error');
                // console.log('error',error.response.data);
               });
             },(Close)=>{});

            ////
          }else{
            this.isLoading = false;
          }
        }else{
          this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
          this.isLoading = false;
          this.modals.open = false;
        }
      }
    },
    searchWord(){
      if(utils.permission('COSTOMER_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/costomer/1'));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/costomer/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    ClipboardVouche(e){
      this.modals.open = false;
    }

  },
  mounted(){
    if(utils.permission('COSTOMER_VIEW')){
    ///// GET List True
      axios(config.api('get', '/costomer/1')).then((response)=>{
        this.totalPages = response.data.ResultData.numpage.page;
        this.table.data = response.data.ResultData.skipdata;
        this.isLoading = false;
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });

      axios(config.api('get', '/promotion/list')).then((response)=>{
        //this.selectPromotion.concat(response.data.ResultData.promotion);
        this.selectPromotion = this.selectPromotion.concat(response.data.ResultData.promotion);
        // console.log(this.selectPromotion);
        
        this.isLoading = false;
      },(error)=>{
        this.isLoading = false;
        this.$alert(error.response.data.Message,'Error','error');
      });
    }else{
      this.$alert('คุณไม่ได้รับอนุญาตให้ใช้งาน','Permission','warning');
      this.isLoading = false;
    }
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
.box-findFriends{
  display: block;
  border-color: #2b3553;
  border-radius: 0.4285rem;
  font-size: 0.75rem;
  margin-bottom: 20px;
  padding: 10px 18px 10px 18px;
  width: 100%;
  line-height: 1.42857;
  color: #FFF !important;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #344655;
  margin-top: 30px;
  cursor: pointer;
}
</style>
