<template>
<div class="row">
  <!-- <loading :active.sync="isLoading" :is-full-page="fullPage"></loading> -->
  <div class="col-12">
    <card :title="table.title">
      <div class="mb-3">
        <div class="card">
          <div class="card-body">
            <div class="info">
              <div><strong>เกมไอดี:</strong> {{ friends.gameId }}</div>
              <div><strong>ชื่อ:</strong> {{ friends.memberName }}</div>
              <div><strong>ยอดที่ได้รับ:</strong> {{ friends.reward }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="table-responsive">
        <div v-if="table.data && table.data.length > 0">
        <base-table-friend-detail :data="table.data" :columns="table.columns" :columnsTh="table.columnsTh" thead-classes="text-primary"></base-table-friend-detail>
      </div>
      <div v-else class="text-center text-muted">
        ยังไม่ได้มีการชวนเพื่อน
      </div>

      </div>
    </card>
  </div>


</div>
</template>
<script>
import {
  BaseTable,
  BaseTableFriendDetail,
} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";

/////
import axios from 'axios';
import config from '@/config.js';
const tableColumns = ["account","deposit", "withdraw"];
const tableColumnsTh = ["ชื่อผู้ใช้", "ยอดฝาก", "ยอดถอน"];
const tableData = [];

export default {
  components: {
    BaseTable,
    SlidingPagination,
    BaseTableFriendDetail,
    Loading
  },
  data() {
    return {
      table: {
        title: "รายละเอียดเพื่อน",
        columns: [...tableColumns],
        columnsTh: [...tableColumnsTh],
        data: [...tableData]
      },
      friends:[],
      listmember:[],
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: '',
    };
  },
  methods: {

    LoadData(){
      console.log('ID_>',this.$route.query.id);
      axios(config.api('get', `/costomer/friendpoint_list/${this.$route.query.id}`)).then((response)=>{  
                console.log(response.data.ResultData.getmoneyfriend[0]);
                this.friends = response.data.ResultData.getmoneyfriend[0];
                this.table.data = response.data.ResultData.getmoneyfriend[0].listmember;
                
                
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });
    }
  },
  mounted(){
    this.LoadData();
  }

};
</script>
<style scoped>
.card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9fafb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

.card-header {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 12px;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.info {
  font-size: 16px;
  color: #555;
}

.info div {
  margin-bottom: 8px;
}
</style>
