<template lang="">
    <div class="row">
         <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
        <div class="col-12">
            <card :title="table.title">
                <div class="row align-center">
                  <div class="col-md-4 text-center" style="position: relative;">
                    <!-- <base-input label="วันที่/เวลา เริ่มต้น" v-model="forms.start" type="date"  placeholder="Enter text"></base-input> -->
                    <datetime type="date" class="form-control input-datetime" format="yyyy-MM-dd" v-model="forms.start"> </datetime>
                  </div>
                  <div class="col-md-4  text-center" style="position: relative;">
                    <!-- <base-input label="วันที่/เวลา สิ้นสุด" v-model="forms.end" type="date"  placeholder="Enter text"></base-input> -->
                    <datetime type="date" class="form-control input-datetime" format="yyyy-MM-dd" v-model="forms.end"></datetime>
                  </div>
                  <div class="col-md-1  text-center" style="position: relative;">
                    <base-button type="primary"  @click="findReport()">
                        ค้นหา
                    </base-button>
                  </div>
                  <div class="col-md-1  text-center" style="position: relative;">
                    <base-button type="warning" @click="exportXlsx()">
                        <i class="tim-icons icon-cloud-download-93"></i>
                    </base-button>
                  </div>
                  <div class="col-md-2  text-center" style="position: relative;">
                    
                    <base-button style="margin-right: 5px;" round icon type="primary"  @click="LoadData()">
                        <i class="tim-icons icon-refresh-02"></i>
                    </base-button>
                  </div> 
                </div>
                <div class="row">
                    <div class="col-12">
                        <br />
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                     <b-card class="text-center shadow-sm" body-class="d-flex align-items-center ">
                        <div class="icon-container">
                            <b-icon icon="cash" variant="primary" font-scale="2"></b-icon>
                            </div>
                            <div class="content ml-3">
                            <p class="mb-0 text-muted">รวมยอดเสีย</p>
                            <h4 class="mb-0 font-weight-bold">{{ finance.totalDeposit ? formatCurrency(finance.totalDeposit) : '0.00' }}</h4>
                        </div>
                    </b-card>
                  </div>
                </div>
                <div class="row">
                    <div class="col-12">
                    <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากชื่อ ... <Enter>" v-on:keyup.enter="searchWord()">
                    </base-input>
                    </div>
                  <div class="col-12">
                    <card class="shadow-sm">
                        <base-table-finance :data="table.data" :columns="table.columns" :columnth="table.columnsTH" :sort="table.sort" @SortTable="SortTable" :title="table.title" @OpenDetail="handleOpenDetail"/>
                    </card>
                    </div>
                </div>
            </card>
        </div> 
         <div>
            <!-- Modal -->
            <b-modal v-model="showModal" centered size="lg">
                <template #modal-title>
                    <span class="custom-title">รายละเอียดรับยอดเสียของ {{ loselogData.name }} </span>
                </template>
                <div>
                    <div class="row">
                        <div class="col-md-3">
                            <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                                <div class="icon-container">
                                    <b-icon icon="cash" variant="primary" font-scale="2"></b-icon>
                                    </div>
                                    <div class="content ml-3">
                                    <p class="mb-0 text-muted">ยอดเสียทั้งหมด</p>
                                    <h4 class="mb-0 font-weight-bold">{{ loselogData.lose? formatCurrency(loselogData.lose) : '0.00' }}</h4>
                                </div>
                            </b-card>
                        </div>
                        <div class="col-md-3">
                            <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                                <div class="icon-container">
                                    <b-icon icon="cash" variant="primary" font-scale="2"></b-icon>
                                    </div>
                                    <div class="content ml-3">
                                    <p class="mb-0 text-muted">ยอดเสียที่ถอน</p>
                                    <h4 class="mb-0 font-weight-bold">{{ loselogData.loseget? formatCurrency(loselogData.loseget) : '0.00' }}</h4>
                                </div>
                            </b-card>
                        </div>
                        <div class="col-md-3">
                            <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                                <div class="icon-container">
                                    <b-icon icon="cash" variant="primary" font-scale="2"></b-icon>
                                    </div>
                                    <div class="content ml-3">
                                    <p class="mb-0 text-muted">ฝาก</p>
                                    <h4 class="mb-0 font-weight-bold">{{ loselogData.totalDeposit ? formatCurrency(loselogData.totalDeposit) : '0.00' }}</h4>
                                </div>
                            </b-card>
                        </div>
                        <div class="col-md-3">
                            <b-card class="text-center shadow-sm" body-class="d-flex align-items-center">
                                <div class="icon-container">
                                    <b-icon icon="cash" variant="primary" font-scale="2"></b-icon>
                                    </div>
                                    <div class="content ml-3">
                                    <p class="mb-0 text-muted">ถอน</p>
                                    <h4 class="mb-0 font-weight-bold">{{ loselogData.totalWithdraw? formatCurrency(loselogData.totalWithdraw) : '0.00' }}</h4>
                                </div>
                            </b-card>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <base-table-Lose-Log :data="tableL.data" :columns="tableL.columns" :columnth="tableL.columnsTH" :sort="tableL.sort" :title="tableL.title"/>
                        </div>
                    </div>
                </div>
                <template #modal-footer>
                    <base-button type="secondary" @click="showModal = false">Close</base-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>
<script>
import { BaseTableFinance, BaseTableLoseLog } from '@/components';
import Loading from 'vue-loading-overlay';
import utils from '@/utils';
import { Datetime } from 'vue-datetime';
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
import axios from 'axios';
import config from '@/config.js';
const tableColumns = [ "gameId", "name", "event", "amount", "result",  "created_at" , "refer"];
const tableColumnsTH = ["เกมไอดี", "ชื่อผู้ใช้", "กระทำ", "จำนวน", "โอโต้",  "วันที่" , "อ้างอิง"];
const tableData = [];
const tableLColumns = [ "type", "amount", "dateTime"];
const tableLColumnsTH = ["กระทำ", "จำนวน", "วันที่/เวลา"];
const tableLData = [];
export default {
    components: {
      Datetime,
      Loading,
      BaseTableFinance,
      BaseTableLoseLog
    },
    data() {
        return {
            fullPage: false,
            isLoading: true,
            table: {
                sort:{
                    even:'desc',
                    col:'created_at'
                },
                title: "รายงานรับยอดเสีย",
                columns: [...tableColumns],
                columnsTH: [...tableColumnsTH],
                data: [...tableData]
            },
            tableL: {
                sort:{
                    even:'desc',
                    col:'created_at'
                },
                title: "รายงานยอดเสีย",
                columns: [...tableLColumns],
                columnsTH: [...tableLColumnsTH],
                data: [...tableLData]
            },
            forms:{},
            finance:[],
            searchName: '',
            allResult:[],
            loselogData:{},
            financeLose:[],
            showModal: false,
            reportData: [],
        }
    },
    methods: {
        LoadData() {
            if (utils.permission('REPORT_MEMBERS_LOSE_VIEW')) {
                const currentDate = new Date().toISOString().split('T')[0];
                axios(config.api('post', '/newReport/membersLose', { params: { startDate: currentDate, endDate: currentDate } }))
                    .then((response) => {
                        //console.log(response.data.membersLose);
                        this.table.data = response.data.ResultData.allResult;
                        this.allResult = response.data.ResultData.allResult;
                        this.finance = response.data.ResultData.typeOfFinance;
                        let filteredData = this.table.data.map(item => ({ id: item.refer.id, name: item.name }));
                        this.reportLose(filteredData);
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        const errorMessage = error.response?.data?.Message || 'เกิดข้อผิดพลาดในการโหลดข้อมูล ติดต่อผู้ดูแลระบบด่วนจ้า';
                        this.$alert(errorMessage, 'Error', 'error');
                    });                
            } else {
                this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน', 'Permission', 'warning');
                this.isLoading = false;
            }
        },
        handleOpenDetail(detail) {
            // ดำเนินการเมื่อปุ่มถูกคลิก เช่น เปิด modal หรือแสดงผลข้อมูล
            // console.log("Detail:", detail.refer.id);
            axios(config.api('get', `/costomer/loselog/${detail.refer.id}`)).then((response)=>{
                //console.log(response.data.ResultData.loseLog[0].created_at);
                this.loselogData.lose = response.data.ResultData.loseLog[0].lose;
                this.loselogData.loseget = response.data.ResultData.loseLog[0].loseget;
                this.loselogData.created_at = response.data.ResultData.loseLog[0].created_at;
                this.loselogData.totalDeposit = response.data.ResultData.loseLog[0].totalDeposit;
                this.loselogData.totalWithdraw = response.data.ResultData.loseLog[0].totalWithdraw;
                this.loselogData.name = detail.name;
                this.tableL.data = response.data.ResultData.loseLog[0].finance;
                this.showModal = true;
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });
            // this.loselogData = detail;
            // console.log(this.cardDetail.status);
            // this.openCardDetail();
            
        },
        createExport(){
            
        },
        reportLose(filteredData) {
            this.reportData = [];
            let requests = filteredData.map(item => 
                axios(config.api('get', `/costomer/loselog/${item.id}`))
                .then(response => {
                    let dataFinance = response.data.ResultData.loseLog[0].finance;
                    return dataFinance.map(financeItem => ({ ...financeItem, name: item.name }));
                })
                .catch(error => {
                    this.$alert(error.response.data.Message, 'Error', 'error');
                    return []; // คืนค่าอาร์เรย์ว่างหากเกิดข้อผิดพลาด เพื่อไม่ให้ Promise.all ล้มเหลว
                })
            );

            Promise.all(requests)
                .then(results => {
                    let combinedData = results.flat(); // รวมข้อมูลจากทุกคำขอ API
                    this.reportData = combinedData;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        formatDate(text){
            return utils.forMatDate(text)
        },
        exportXlsx() {
            // this.$alert('ยังไม่สามารถใช้งานฟังก์ชันนี้ได้', 'Error', 'error');
            // 🔹 แปลงข้อมูลให้ตรงกับโครงสร้าง `reportData`
            let formattedData = this.reportData.map(row => ({
                type: row.type,          // ประเภทการทำรายการ (ฝาก, ถอน ฯลฯ)
                amount: row.amount,      // จำนวนเงิน
                dateTime: row.dateTime,  // วันที่และเวลา
                name: row.name           // ชื่อผู้ใช้
            }));

            // 🔹 สร้างโครงสร้าง columns ให้ตรงกับข้อมูล
            let DataSheet = [
                {
                    sheet: "FinancialReport",
                    columns: [
                        { label: "ประเภท", value: "type" },
                        { label: "จำนวนเงิน", value: "amount" },
                        { label: "วันที่และเวลา", value: "dateTime" },
                        { label: "ชื่อผู้ใช้", value: "name" }
                    ],
                    content: formattedData
                }
            ];
            // 🔹 ส่งข้อมูลไปที่ API
            axios(config.api('post', '/toxlsx', DataSheet ))
                .then((response) => {
                if (response.data.Code === 200 && response.data.ResultData) {
                    // เปิดไฟล์ที่สร้างจาก API
                    window.open(`${config.apiplayload}${response.data.ResultData}`,'_blank');
                } else {
                    throw new Error("Download URL not received");
                }
                this.isLoading = false;
            })
            .catch((error) => {
                this.isLoading = false;
                console.error("File export error:", error);
                const errorMessage = error.response?.data?.Message || 'เกิดข้อผิดพลาดในการโหลดข้อมูล ติดต่อผู้ดูแลระบบด่วนจ้า';
                this.$alert(errorMessage, 'Error', 'error');
            });
        },
        findReport() {
            this.isLoading = true;
            axios(config.api('post', '/newReport/membersLose', { params: { startDate: this.forms.start, endDate: this.forms.end } }))
                    .then((response) => {
                        this.table.data = response.data.ResultData.allResult;
                        this.allResult = response.data.ResultData.allResult;
                        this.finance = response.data.ResultData.typeOfFinance;
                        let filteredData = this.table.data.map(item => ({ id: item.refer.id, name: item.name }));
                        this.reportLose(filteredData);
                        this.isLoading = false;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        const errorMessage = error.response?.data?.Message || 'เกิดข้อผิดพลาดในการโหลดข้อมูล ติดต่อผู้ดูแลระบบด่วนจ้า';
                        this.$alert(errorMessage, 'Error', 'error');
                    });
        },
        searchWord() {
            if (this.searchName.length <= 0) {
                this.table.data = this.allResult;
            }
            else{
                this.table.data = this.filteredData();
            }
        },
        SortTable(column){ 
            // console.log('column',column);
            
            let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
            let ObjK = {};
            ObjK[column] = this.table.data;
            this.table.sort.col = column;
            this.table.sort.even = sortType; 
            this.table.data = utils.Sort[sortType](ObjK) 

        },
        formatCurrency(value) {
            return new Intl.NumberFormat('th-TH', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
            }).format(parseFloat(value));
        },
        filteredData() {
            const searchTerm = this.searchName.toLowerCase();
            const filtered = this.table.data.filter((item) => {
                return (
                    item.gameId.toLowerCase().includes(searchTerm) ||
                    item.name.toLowerCase().includes(searchTerm)
                );
            });
            return filtered.length > 0 ? filtered : [];
        }
    },
    mounted() {
        let startDate =  utils.forMatDate(Math.floor(Date.now()));
        // let endDate =  utils.forMatDate((Math.floor(Date.now() / 1000)+((60*60)*24))*1000);
        let endDate =  utils.forMatDate(Math.floor(Date.now()));
        this.forms.start = `${startDate}`;
        this.forms.end = `${endDate}`;
        this.LoadData();        
    },
    computed: {
  },
}
</script>
<style scoped>
.row.align-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.custom-title {
  font-size: 16px;
  font-weight: bold;
}
</style>