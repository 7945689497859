<template lang="">
    <div class="container mt-4">
    <b-card class="p-4">
        <div class="row">
            <div class="col-md-12">
                <b-form-group label="แผนก">
                    <b-form-input v-model="liveChat.departament" readonly></b-form-input>
                </b-form-group>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex">
                <div class="switch-container" @click="toggleSwitch">
                    <div :class="['switch', { 'switch-on': liveChat.status }]">
                    <div class="switch-handle"></div>
                    </div>
                    <p class="status-text">
                    <strong>{{ liveChat.status ? "เปิด" : "ปิด" }}</strong>การใช้งาน Live Chat
                    </p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <hr />
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 d-flex justify-content-between">
                <b-button variant="primary" @click="saveData">บันทึก</b-button>
                <b-button variant="secondary" @click="openChat">เปิดหน้าต่างแชท</b-button>
            </div>
        </div>
    </b-card>
  </div>
</template>

<script>
import axios from 'axios';
import config from '@/config.js';
import Loading from 'vue-loading-overlay';
export default {
    components: {
      Loading,
    },
    data() {
        return {
            isLoading: false,
            fullPage: true,
            liveChat:{
                departament: "",
                status: false
            },
            urlLivechat: "",
            bofInfo:{}
        }
    },
    methods: {
    toggleSwitch() {
      this.liveChat.status = !this.liveChat.status;
    },
    saveData() {
        this.$confirm('บันทึกการเปลี่ยนแปลง ?', 'แจ้งเตือน', 'question').then(() => {
            // console.log('ok');/livechat/update
            axios(config.api('post', '/livechat/update',{liveChat:this.liveChat})).then((response)=>{
                this.$alert('บันทึกเรียบร้อย','Success','success');
            },(error)=>{
                this.isLoading = false;
                this.$alert(error.response.data.Message,'Error','error');
            });
        }).catch(() => {
        });
    },
    openChat() {
        window.open(this.urlLivechat, "_blank");
    },
    LoadData(){
        axios(config.api('get', '/livechat/Livechat')).then((response)=>{
            this.liveChat = response.data.ResultData.livechat;
            this.bofInfo = response.data.ResultData.bofInfo;
            this.urlLivechat = `https://chat.a886.xyz/auth/admin?department=${this.liveChat.departament}&name=${this.bofInfo.name}&username=${this.bofInfo.user}&password=${this.bofInfo.pass}`
        },(error)=>{
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
        });
    }
  },
  mounted() {
    this.LoadData();
  }

}
</script>

<style>
.row {
    margin-bottom: 20px;
}
.switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.switch {
  width: 50px;
  height: 26px;
  background-color: #ccc;
  border-radius: 15px;
  position: relative;
  transition: 0.3s;
}

.switch-handle {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 3px;
  left: 3px;
  transition: 0.3s;
}

.switch-on {
  background-color: #007bff;
}

.switch-on .switch-handle {
  transform: translateX(24px);
}

.status-text {
  font-size: 16px;
}
</style>