<template>
<div class="row">
  <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
  <div class="col-12">
    <card :title="table.title">

      <div class="mb-3">
        <card>
          <div class="row">
            <div class="col-lg-6">
              <base-input addon-left-icon="tim-icons icon-zoom-split" v-model="searchName" v-on:change="searchWord()" placeholder="ค้นหาจากเลขบัญชีเลขท้าย/ชื่อ ... <Enter>" v-on:keyup.enter="searchWord()">
              </base-input>
            </div>
            <div class="col-lg-3 text-right">
              <base-button type="primary" @click="openForm()">เพิ่มรายการโอน</base-button>
            </div>
            <div class="col-3 text-right">  
              <base-button type="warning" size="md" class="pull-right " @click="onExport"> <i class="tim-icons icon-cloud-download-93"></i> XLSX </base-button>
            </div> 
          </div>
        </card>
      </div>
      <div class="table-responsive">
        <base-table-get-money :data="table.data" :columns="table.columns" :sort="table.sort" @SortTable="SortTable" thead-classes="text-primary"> </base-table-get-money>
        <sliding-pagination :current="currentPage" :total="totalPages" @page-change="pageChangeHandler"></sliding-pagination>
      </div>
    </card>
  </div>
  <!-- /// modals /// -->
  <modal :show.sync="modals.open"
         body-classes="p-0"
         modal-classes="modal-dialog-centered modal-sm">
      <card type="secondary"
            header-classes="bg-white pb-5"
            body-classes="px-lg-5 py-lg-5"
            class="border-0 mb-0">
          <template>
            <strong>เพิ่มรายการ</strong>
          </template>
          <template>
            <form role="form">
              <div class="row">
                <div class="col-lg-6">
                  <base-input
                      :class="{'has-success':findMember.statust == 'true','has-danger':findMember.statust == 'false', 'has-': findMember.statust == ''}"
                      label="เบอร์โทร หรือ ยูเซอร์" v-model="findMember.kword"
                      @change="searchMember()"
                      v-on:keyup.enter="searchMember()"
                      type="text"
                      placeholder="Enter text"></base-input>
                      <span style="color: red;" v-if="promotionAlert">*ผู้ใช้ติดโปรอยู่ ถ้าเพิ่มเงินจะเข้ากระเป๋าทันที</span>
                </div>
                <div class="col-lg-6">
                  <base-input label="จำนวนเงิน" v-model="forms.amount" type="number"  placeholder="Enter text"></base-input>
                </div>
                <div class="col-lg-12">
                  <base-input label="Ref.1" v-model="forms.ref" type="text"  placeholder="Enter text"></base-input>
                </div>
                <div class="col-lg-6">
                  <base-input label="วันที่" v-model="forms.dates" type="date"  placeholder="Enter text"></base-input>
                </div>
                <div class="col-lg-6">
                  <base-input label="เวลา" v-model="forms.times" type="time"  placeholder="Enter text"></base-input>
                </div>
                <div class="col-lg-12">
                  <div class="row" style="margin-right: 3px; margin-left: 3px;">
                    <div class="col-4" @click="forms.banks = items" v-for="(items,index) in listBank"  :key="index">
                      <div :class="{'row select-bank': forms.banks._id != items._id,'row select-bank active': forms.banks._id == items._id}">
                        <div class="col-3">
                          <img :src="`/img/icons_sys/${(items.type == 'tmn')?'true':items.type}.jpg`" alt="">
                        </div>
                        <div class="col-9">
                          <h6>{{items.nameBank}}</h6>
                          <p>{{items.name}}</p>
                          <p>{{items.number | fotmatNumBank}}</p>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-lg-12 mt-3">
                  <!-- IMAGE -->
                    <img :src="forms.img.Base64 || ''" alt="">
                </div>
                <div class="col-lg-12 mt-3">
                  <base-file type="primary" value="อัพโหลดรูป" @filedata="onFileChange" :block="true" :param="{'path':`/${agentId}/getMoney`,'type':'receipt','name':''}" />
                </div>

              </div>

            </form>
          </template>
          <template slot="footer">
            <base-button type="secondary" @click="modals.open = false">Close</base-button>
            <base-button v-if="findMember.statust == 'true' && forms.amount != '' && forms.times != ''" type="primary" style="width: 82%;" @click="saveChanges()">Submit</base-button>
          </template>
      </card>
  </modal>



</div>
</template>
<script>
import {
  BaseTableGetMoney,
  BaseFile,
  BaseAlert,
  Modal
} from "@/components";
// Import component
import Loading from 'vue-loading-overlay';
import SlidingPagination from 'vue-sliding-pagination'
import utils from '../utils';
import 'vue-loading-overlay/dist/vue-loading.css';
import "vue-sliding-pagination/dist/style/vue-sliding-pagination.css";
/////
import axios from 'axios';
import config from '../config.js';
/////
const tableColumns = [ "type","owner", "tel","amount", "admin" ,"atdate","created_at"];
const tableData = [];
const adminInfo = utils.localDB.jwt('token');
export default {
  components: {
    BaseTableGetMoney,
    SlidingPagination,
    Modal,
    BaseAlert,
    BaseFile,
    Loading
  },
  data() {
    return {
      agentId: utils.localDB.jwt('token').agentId,
      table: {
        sort:{
          even:'desc',
          col:'created_at'
        },
        title: "เพิ่มเงินให้ลูกค้า",
        columns: [...tableColumns],
        data: [...tableData]
      },
      isAlert: {open:false,tip:'',msg:''},
      modals: {
        open: false,
        data :{},
      },
      findMember:{
        kword:'',
        statust:''
      },
      forms:{
        owner:'',
        amount:'',
        img:{
          Base64:'',
          param:{}
        },
        ref:'',
        dates:'',
        times:'',
        commit:"",
        admin:{
          _id: adminInfo._id,
          name: adminInfo.name
        },
        banks:''
      },
      listBank:[],
      currentPage: 1,
      totalPages: 1,
      isLoading: true,
      fullPage: true,
      searchName: '',
      promotionAlert: false
    };
  },
  methods: {
    LoadData(){
      if(utils.permission('LISTGETMONEY_VIEW')){
        (async () => {
          try {
            let tableReq = await axios(config.api('get', '/getMoney/1'));
            this.totalPages = tableReq.data.ResultData.numpage.page;
            this.table.data = tableReq.data.ResultData.skipdata;
            this.listBank = tableReq.data.ResultData.listBank;
            this.forms.banks = tableReq.data.ResultData.listBank[0]
            this.forms.dates = new Date().toISOString().slice(0,10);
            this.isLoading = false;
          } catch (error) {
            this.isLoading = false;
            this.$alert(error.response.data.Message,'Error','error');
          }
        })();
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
        this.isLoading = false;
      }
    },
    SortTable(column){ 
      let sortType = (this.table.sort.col == column)?(this.table.sort.even == 'desc')?'aec':'desc':this.table.sort.even;
      let ObjK = {};
      ObjK[column] = this.table.data;
      this.table.sort.col = column;
      this.table.sort.even = sortType; 
      this.table.data = utils.Sort[sortType](ObjK) 
    },
    pageChangeHandler(selectedPage) {
      this.isLoading = true;
      (async () => {
        try {
          let tableReq = await axios(config.api('get', '/getMoney/'+selectedPage));
          this.table.data = tableReq.data.ResultData.skipdata;
          this.currentPage = selectedPage;
          this.isLoading = false;
        } catch (error) {
          this.isLoading = false;
          this.$alert(error.response.data.Message,'Error','error');
        }
      })();
    },
    ////
    openForm(){
      if(utils.permission('LISTGETMONEY_ADD')){
        this.modals.open = true;
        this.findMember.kword = '';
        this.findMember.statust = '';
        this.forms.owner = '';
        this.forms.amount = '';
        this.forms.times = '';
        this.forms.img = {
          Base64:'',
          param:{}
        };
        this.forms.ref = '';
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      }
    },
    checkpro(usepro){
      if(usepro.proid != '1'){
        this.promotionAlert = true;
      }else{
        this.promotionAlert = false;
      }
    },
    ////
    searchMember(){
      axios(config.api('post', '/getMoney/findMember',{kword:this.findMember.kword})).then((response)=>{
        this.findMember.statust = 'true';
        this.forms.owner = response.data.ResultData;
        this.checkpro(response.data.ResultData.usepro);
      },(error)=>{
        this.findMember.statust = 'false';
      }); 
    },
    ///// Uplod bin
    onFileChange(e){
      if(utils.permission('LISTGETMONEY_ADD')){
        this.forms.img = e;
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      }
    },
    /// Save
    saveChanges(){
      this.$prompt("เหตุผลการกระทำ").then((commit) => {
        this.isLoading = true;
        this.forms.commit = commit;
        axios(config.api('post', '/getMoney/add',this.forms)).then((response)=>{
          this.isLoading = false;
          this.modals.open = false;
          this.LoadData();
        },(error)=>{
          this.isLoading = false;
          this.modals.open = false;
          this.$alert(error.response.data.Message,'Error','error');
        });
       },(Close)=>{});
    },
    ////
    searchWord(){
      if(utils.permission('LISTGETMONEY_VIEW')){
        if(this.searchName.length <= 0){
          this.isLoading = true;
          (async () => {
            try {
              let tableReq = await axios(config.api('get', '/getMoney/1'));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }else if(this.searchName.length > 3){
          (async () => {
            try {
              let tableReq = await axios(config.api('post', '/getMoney/search',{kword:this.searchName}));
              this.totalPages = tableReq.data.ResultData.numpage.page;
              this.table.data = tableReq.data.ResultData.skipdata;
              this.isLoading = false;
            } catch (error) {
              this.isLoading = false;
              this.$alert(error.response.data.Message,'Error','error');
            }
          })();
        }
      }else{
        this.$alert('คุณไม่ได้รับอณุญาติให้ใช้งาน','Permission','warning');
      }
    },
    onExport() { 
      // const tableColumns = [ "type","owner", "tel","amount", "admin" ,"atdate","created_at"];
      let DataSheet = [
            {
                sheet:  this.table.title,
                columns:[ 
                    { label: "ธนาคาร", value: "type" },
                    { label: "ชื่อ", value: "owner" },
                    { label: "เบอร์", value: "tel" },
                    { label: "จำนวน", value: "amount" },
                    { label: "โดย@", value: "admin" },
                    { label: "@date", value: "atdate" }, 
                    { label: "เวลา", value: "created_at" , format:'h:mm AM/PM'}, 
                ],
                content:this.table.data
            }
        ];
        axios(config.api('post', `/toxlsx`,DataSheet)).then((response) => { 
          window.open(`${config.apiplayload}/${response.data.ResultData}`,'_blank');
          this.isLoading = false; 
        }, (error) => {
          this.isLoading = false;
          this.$alert(error.response.data.Message, 'Error', 'error');
        });
    },
  },
  mounted(){
    this.LoadData();
  },
  beforeCreate() {

  }

};
</script>
<style>
.modal-content{
  top: -100px;
  position: fixed;
}
</style>
